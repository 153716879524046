import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DeleteAccount from "./pages/DeleteAccount";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import SearchPage from "./pages/Search";
import BedaMarketplace from "./pages/BedaMarketplace";

const App = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/beda-marketplace" element={<BedaMarketplace />} />
				<Route path="/delete-account" element={<DeleteAccount />} />
				<Route path="/privacy" element={<PrivacyPolicy />} />
				<Route path="/search" element={<SearchPage />} />
				<Route path="/terms-of-service" element={<TermsOfService />} />
			</Routes>
		</Router>
	);
};

export default App;
